import { message } from "antd";
import { formatDistance } from "date-fns";
import {
  extractPsychotherapyExplanation,
  getCptCodeLabelByPsychotherapyMinutes,
  getCptSuggestedCode,
  getNotesType,
  NoteType,
  showCoding,
  totalTimeSpentOnPsychotherapy,
} from "../domain/notes.domain";
import { UserInfoProps } from "../slices/authSlice";
import { TClientForm, TNotes } from "../types/index.type";
import {
  EventType,
  NoteSection,
  trackEvent
} from "./analytics";

export const convertPlainTextToHtml = (text: string): string => {
  if (!text) {
    return "";
  }

  return text
    .split("\n")
    .map((line: string) => {
      if (line.trim() === "") {
        return "<p><br></p>";
      } else {
        return `<p>${line}</p>`;
      }
    })
    .join("");
};

export function formatDuration(seconds: number) {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedHours = hrs.toString().padStart(2, "0");
  const formattedMinutes = mins.toString().padStart(2, "0");
  const formattedSeconds = secs.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function formatTimeToSecondsMinsHoursFromSeconds(seconds: number) {
  if (isNaN(seconds) || !seconds) {
    return "0 sec";
  }
  if (seconds < 60) {
    return `${seconds} sec`;
  } else if (seconds >= 60 && seconds < 3600) {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins} min ${secs} sec`;
  } else {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs} hrs ${mins} min ${secs} sec`;
  }
}

export const showPaymentModal = (userInfo: UserInfoProps) => {
  return (
    !userInfo.is_paying &&
    userInfo.total_recordings >= userInfo.free_recordings_limit
  );
};

export const copyContent = (
  notesDetails: TNotes,
  htmlContent: string,
  section: NoteSection,
  plainTextContent = ""
): void => {
  if (notesDetails) {
    trackEvent(EventType.COPY_NOTE, {
      noteType: getNotesType(notesDetails.note_type),
      noteSection: section,
      recordingId: notesDetails?.id,
    });
    const copyToClipboard = async (
      htmlContent: string,
      plainTextContent = ""
    ) => {
      try {
        if (plainTextContent) {
          await navigator.clipboard.write([
            new ClipboardItem({
              "text/html": new Blob([htmlContent], { type: "text/html" }),
              "text/plain": new Blob([plainTextContent], {
                type: "text/plain",
              }),
            }),
          ]);
        } else {
          await navigator.clipboard.write([
            new ClipboardItem({
              "text/html": new Blob([htmlContent], { type: "text/html" }),
              "text/plain": new Blob([htmlContent], { type: "text/plain" }),
            }),
          ]);
        }
        console.log("Content copied to clipboard successfully!");
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };

    copyToClipboard(htmlContent, plainTextContent);
  }
};

export const copySectionContent = (
  notesDetails: TNotes,
  text: string,
  section: NoteSection
) => {
  if (notesDetails) {
    trackEvent(EventType.COPY_NOTE, {
      noteType: getNotesType(notesDetails.note_type),
      noteSection: section,
      recordingId: notesDetails?.id,
    });
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard");
  }
};

export const copySessionDetails = (notesDetails: TNotes) => {
  if (notesDetails) {
    trackEvent(EventType.COPY_NOTE, {
      noteType: getNotesType(notesDetails.note_type),
      noteSection: NoteSection.SESSION_DETAILS,
      recordingId: notesDetails?.id,
    });
    let copiedText = "";

    if (
      notesDetails?.location_type != null ||
      notesDetails?.duration_type != null
    ) {
      const details = ["Session Details:"];

      if (notesDetails?.location_type != null) {
        details.push(
          `Location: ${
            notesDetails.location_type === 0 ? "Telehealth" : "In-person"
          }`
        );
      }

      if (notesDetails?.duration_type != null) {
        details.push(
          `CPT Code: ${durationTextFromType(notesDetails.duration_type)}\n`
        );
      }

      copiedText = `${details.join("\n\n")}\n\n`;
    }
    navigator.clipboard.writeText(copiedText);
    message.success("Copied to clipboard");
  }
};

export const copyAddonDetails = (recordingDetails: TNotes) => {
  const psychotherapyMinutes = recordingDetails.psychotherapy_minutes || 0;
  const psychotherapyExplanation = recordingDetails.psychotherapy_minutes_explanation || "";

  const details = ["Psychotherapy Add-on:"];
  let copiedText = "";

  if (showCoding(recordingDetails)) {
    const cptCodeLabel = getCptCodeLabelByPsychotherapyMinutes(
      psychotherapyMinutes,
      recordingDetails
    );
    details.push(`Add-On CPT Code: ${cptCodeLabel}`);
  }

  if (psychotherapyMinutes) {
    details.push(`Psychotherapy Minutes: ${psychotherapyMinutes}`);
  }

  if (psychotherapyExplanation) {
    details.push(`Explanation: ${psychotherapyExplanation}`);
  }

  copiedText = details.join("\n\n");

  navigator.clipboard.writeText(copiedText);
  message.success("Copied to clipboard");
};

export const copyCPTDetails = (recordingDetails: TNotes) => {
  const CPTExplanation = recordingDetails.suggested_cpt_code_explanation || "";

  const details = ["Suggested CPT code:"];
  let copiedText = "";

  if (showCoding(recordingDetails)) {
    const cptCodeLabel = getCptSuggestedCode(recordingDetails);
    details.push(`CPT Code: ${cptCodeLabel}`);
  }

  if (CPTExplanation) {
    details.push(`Explanation: ${CPTExplanation}`);
  }

  copiedText = details.join("\n\n");

  navigator.clipboard.writeText(copiedText);
  message.success("Copied to clipboard");
};

export const durationTimeFromType = (type: string | number) => {
  switch (parseInt(type as string)) {
    case 0:
    case 13:
      return "30 min";
    case 1:
    case 14:
      return "45 minutes";
    case 2:
    case 15:
      return "60 minutes";
    case 3:
      return "15-29 minutes";
    case 4:
      return "30-44 minutes";
    case 5:
      return "45-59 minutes";
    case 6:
      return "60-74 minutes";
    case 7:
      return "10-19 minutes";
    case 8:
      return "20-29 minutes";
    case 9:
      return "30-39 minutes";
    case 10:
      return "40-54 minutes";
    case 11:
      return "45-60 minutes";
    case 12:
      return "45-60 minutes";
    case 16:
      return "60 minutes";
    case 17:
      return "45-60 minutes";
    case 18:
      return "45-60 minutes";
    case 19:
      return "45-60 minutes";
    default:
      return "-";
  }
};

export const durationTextFromType = (type: string | number): string => {
  const parsedType = parseInt(type as string);

  switch (parsedType) {
    case 0:
    case 13:
      return "90832: Psychotherapy, 30 minutes";
    case 1:
    case 14:
      return "90834: Psychotherapy, 45 minutes";
    case 2:
    case 15:
      return "90837: Psychotherapy, 60 minutes";
    case 3:
      return "99202: New patient, 15-29 minutes";
    case 4:
      return "99203: New patient, 30-44 minutes";
    case 5:
      return "99204: New Patient, 45-59 minutes";
    case 6:
      return "99205: New Patient, 60-74 minutes";
    case 7:
      return "99212: Established Patient, 10-19 minutes";
    case 8:
      return "99213: Established Patient, 20-29 minutes";
    case 9:
      return "99214: Established Patient, 30-39 minutes";
    case 10:
      return "99215: Established Patient Visit, 40-54 minutes";
    case 11:
      return "90791: Psychiatric Diagnostic Evaluation";
    case 12:
      return "90792: Psychiatric Diagnostic Evaluation Incl. Medical Services";
    case 16:
      return "90839: Psychotherapy for crisis situations, first 60 minutes";
    case 17:
      return "90846: Family Psychotherapy without patient present, 50 minutes";
    case 18:
      return "90847: Family Psychotherapy with patient present, 50 minutes";
    case 19:
      return "90853: Group Psychotherapy, not family-related";
    default:
      return "-";
  }
};

export const sortClientsByName = (clients: TClientForm[]) => {
  return clients.sort((a: any, b: any) => a.name.localeCompare(b.name));
};

export const getFirstLetterOfNameAndSurname = (name: string) => {
  if (!name) return "";
  const nameArray = name.split(" ");
  const initials =
    nameArray.length > 1
      ? `${nameArray[0].charAt(0)}${nameArray[1].charAt(0)}`
      : nameArray[0].charAt(0);
  return initials.toUpperCase();
};

export const getAnalyticsKeyOnTheBasisOfType = (type: string) => {
  switch (type) {
    case "audio":
      return EventType.CLICKED_ON_CAPTURE_DEMO_PATH;
    case "dictate":
      return EventType.CLICKED_ON_DICTATE_DEMO_PATH;
    case "upload":
      return EventType.CLICKED_ON_UPLOAD_DEMO_PATH;
    case "write":
      return EventType.CLICKED_ON_DESCRIPTION_DEMO_PATH;
    default:
      return EventType.CLICKED_ON_CAPTURE_DEMO_PATH;
  }
};

export function formatDistanceFromInputDateTimeToNow(dateTimeString: string) {
  return formatDistance(new Date(dateTimeString), new Date(), {
    addSuffix: true,
  });
}

export const htmlToText = (htmlContent: string): string => {
  return htmlContent
    .replace(/<br>/g, "\n") // replace <br> with newline
    .replace(/<\/p>/g, "\n") // replace </p> with newline
    .replace(/&nbsp;/g, " ") // replace &nbsp; with space
    .replace(/<[^>]*>?/gm, ""); // remove any other html tags
};

export const replaceNewLineWithBreakTag = (text: string): string => {
  return text.replace(/\n/g, "<br>");
};

export const copyContentAndNotify = (
  notesDetails: TNotes,
  htmlContent: string,
  plainTextContent: string
) => {
  copyContent(notesDetails, htmlContent, NoteSection.ALL, plainTextContent);
  message.success("All content copied to clipboard");
};

export const generateHtmlAndPlainText = (
  copiedText: string,
  additionalText: string,
  addOnDetails: string
) => {
  const htmlToCopy = replaceNewLineWithBreakTag(
    copiedText + additionalText + addOnDetails
  );
  const plainTextToCopy = `${copiedText}\n\n${additionalText}\n\n${addOnDetails}`;
  return { htmlToCopy, plainTextToCopy };
};

export const copyNote = (
  notesDetails: TNotes,
  copiedText: string,
  addOnDetails: string
) => {
  switch (notesDetails.note_type) {
    case 0: // SOAP
      copiedText += `Subjective: \n${notesDetails.subject}\n\nObjective: \n${notesDetails.objective}\n\nAssessment: \n${notesDetails.assessment}\n\nPlan: \n${notesDetails.plan}`;
      copyContentAndNotify(notesDetails, 
       replaceNewLineWithBreakTag(copiedText),
       copiedText 
      );
      break;
    case 1: // Intake
      copyContentAndNotify(
        notesDetails,
        replaceNewLineWithBreakTag(copiedText + notesDetails.intake_note),
        `${copiedText}\n\n${htmlToText(notesDetails.intake_note)}`
      );
      return;
    case 2: // DAP Intake
      copiedText += `Data: \n${notesDetails.data}\n\nAssessment: \n${notesDetails.assessment}\n\nPlan: \n${notesDetails.plan}`;
      copyContentAndNotify(notesDetails, 
        replaceNewLineWithBreakTag(copiedText),
        copiedText 
       );
      break;
    case 4: // EAP Intake
      copyContentAndNotify(
        notesDetails,
        replaceNewLineWithBreakTag(copiedText + notesDetails.eap_intake_note),
        `${copiedText}\n\n${htmlToText(notesDetails.eap_intake_note)}`
      );
      return;
    case 5: // Psych Intake
      copyContentAndNotify(
        notesDetails,
        replaceNewLineWithBreakTag(copiedText + notesDetails.psych_intake_note + addOnDetails),
        `${copiedText}\n\n${htmlToText(copiedText + notesDetails.psych_intake_note + addOnDetails)}`
      );
      return;
    case 6: // SOL Psych Intake
      const solPsychIntake = generateHtmlAndPlainText(
        copiedText,
        notesDetails?.sol_psych_intake_note || '',
        addOnDetails
      );
      copyContentAndNotify(
        notesDetails,
        solPsychIntake.htmlToCopy,
        solPsychIntake.plainTextToCopy
      );
      return;
    case 7: // SOL Psych Followup
      const solPsychFollowup = generateHtmlAndPlainText(
        copiedText,
        notesDetails?.sol_psych_followup_note || '',
        addOnDetails
      );
      copyContentAndNotify(
        notesDetails,
        solPsychFollowup.htmlToCopy,
        solPsychFollowup.plainTextToCopy
      );
      return;
    case 8: // SOL Therapy Intake
      copyContentAndNotify(
        notesDetails,
        replaceNewLineWithBreakTag(copiedText + notesDetails?.sol_therapy_intake_note),
        `${copiedText}\n\n${notesDetails?.sol_therapy_intake_note}`
      );
      return;
    case 9:
    case 10: // SOL Therapy Followup
      copyContentAndNotify(
        notesDetails,
        replaceNewLineWithBreakTag(copiedText + notesDetails?.sol_therapy_followup_note),
        `${copiedText}\n\n${notesDetails?.sol_therapy_followup_note}`
      );
      return;
     case 11: 
        const htmlToCopy = replaceNewLineWithBreakTag(copiedText + notesDetails?.psych_followup_note + addOnDetails);
        const plainTextToCopy = `${copiedText}\n\n${notesDetails?.psych_followup_note}\n\n${addOnDetails}`;
        copyContent(
          notesDetails,
          htmlToCopy,
          NoteSection.ALL,
          plainTextToCopy
        );
        message.success("All content copied to clipboard");
        return;
    case 12:
      copyContentAndNotify(
        notesDetails,
        replaceNewLineWithBreakTag(copiedText + notesDetails.document),
        `${copiedText}\n\n${htmlToText(copiedText + notesDetails.document)}`
      );
      return;
    default: // EMDR
      copyContentAndNotify(
        notesDetails,
        replaceNewLineWithBreakTag(copiedText + notesDetails?.emdr_note),
        `${copiedText}\n\n${htmlToText(notesDetails?.emdr_note)}`
      );
      return;
  }
};