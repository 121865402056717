import { FileAddOutlined, FileTextOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { Alert, Col, Divider, Drawer, Flex, message, Row, Tabs } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNetworkState } from "react-use";
import { Outlet } from "react-router-dom";
import { io, type Socket } from "socket.io-client";
import Swal from "sweetalert2";
import ActiveRecordingView from "../../components/ActiveRecordingView/ActiveRecordingView.component";
import ClientView from "../../components/ClientView/ClientView.component";
import CreateClient from "../../components/CreateClient/CreateClient.component";
import CreateNoteModal from "../../components/CreateNoteModal/CreateNoteModal.component";
import CustomiseNote from "../../components/Customise/customise-note.component";
import RecordCTA from "../../components/RecordCTA/RecordCTA.component";
import Share from "../../components/Share/Share.modal";
import { NoteType } from "../../domain/notes.domain";
import { useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import { getAllDcmCodes } from "../../service/client.service";
import {
  getCustomizationList,
  handleCancelRecording,
  handleCaptureAudioSubmission,
} from "../../service/notes.service";
import {
  setAddClientView,
  setCreateClientState,
  setCreateModalState,
  setCustomiseSectionState,
  setDemoState,
  setEditingState,
  setIsDemoPath,
  setSelectedNoteForEditing,
  setShowRecordingView,
  setSubmissionProgressState,
  setUploadProgressState,
  setUploadType,
} from "../../slices/appStateSlice";
import {
  setClientForm,
  setCustomizationList,
  setDsm,
} from "../../slices/clientSlice";
import {
  setIsPaused,
  setIsRecording,
  setMediaRecorder,
  setRecordingDetail,
} from "../../slices/recordingsSlice";
import type { AppDispatch } from "../../store";
import { EventType, trackEvent } from "../../utils/analytics";
import { MAX_RECORDING_TIME_SECONDS } from "../../utils/constants";
import {
  isTooShort,
  stopMediaRecorderAndStopTracks,
} from "../../utils/recording.utils";
import { releaseWakeLock, requestWakeLock } from "../../utils/wakeScreen";
import styles from "./home.module.scss";
import { useIntercom } from "react-use-intercom";
import useIntercomWithData from "../../hooks/useIntercomWithData";
import { setShowPaymentModal } from "../../slices/userSlice";
import { isLastPlusOneNote } from "../../domain/user.domain";
import { eventEmitter } from "../../utils/eventEmitter";
import { checkMicrophoneMuteStatus } from "./utils";

const maxRetries = 900;
const retryDelayMs = 1000;

const HomePage = () => {
  let chunks: Array<ArrayBuffer> = [];
  const componentName = "home";
  const dispatch = useDispatch<AppDispatch>();
  const isMobileView = useIsMobileView();
  const { show } = useIntercom();

  const {
    isCustomiseSectionVisible,
    uploadType,
    showRecordingView,
    isCreateModalVisible,
    isCreateClientDrawerVisible,
    demoState,
  } = useAppSelector((state) => state.appState);

  const networkState = useNetworkState();

  const {
    recordMimeType,
    mediaRecorder,
    isRecording,
    isPaused,
    recordingDetail,
  } = useAppSelector((state) => state.recordings);

  const {
    audioDevices
  } = useAppSelector((state) => state.appState);

  const { notesDetails } = useAppSelector((state) => state.notes);
  const userInfo = useAppSelector((state) => state.auth.userInfo);

  const { bootWithData } = useIntercomWithData();

  const [recordingTime, setRecordingTime] = React.useState<number>(0);
  const captureRecordingIdRef = React.useRef("");
  const captureAudioRef = React.useRef<Socket | null>(null);
  const recordingInterval = React.useRef<NodeJS.Timeout | null>(null);
  const [isCaptureEnabled, setIsCaptureEnabled] =
    React.useState<boolean>(false);
  const [isMicrophoneMuted, setIsMicrophoneMuted] = React.useState(false);
  const currentBufferRef = React.useRef(0);
  const previousBufferRef = React.useRef(0);
  const noBufferCountRef = React.useRef(0);
  const totalBufferCountRef = React.useRef(0);


  const populateStore = () => {
    getAllDcmCodes()
      .then((response) => {
        const dsm = response.data;
        dispatch(setDsm(dsm));
      })
      .catch((error) => {
        console.error("Error fetching DCM codes:", error);
      });
    getCustomizationList()
      .then((response) => {
        dispatch(setCustomizationList(response.data));
      })
      .catch((error) => {
        console.error("Error fetching customization list:", error);
      });
  };

  React.useEffect(() => {
    dispatch(setIsDemoPath(false));
    populateStore();
    bootWithData();

    const searchParams = new URLSearchParams(window.location.search);
    const shouldOpenCreateNoteDrawer =
      searchParams.get("open_note_drawer") === "true";
    if (shouldOpenCreateNoteDrawer) {
      dispatch(setCreateModalState(true));
    }
  }, [dispatch]);

  const initWebsocket = async (audio_file_id: string) => {
    captureRecordingIdRef.current = audio_file_id;
    return new Promise((resolve) => {
      const socketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/ws/notes/`;
      const token = localStorage.getItem("token");
      const socket = io(socketUrl, {
        autoConnect: false,
        transports: ["websocket"],
        reconnectionAttempts: maxRetries,
        auth: {
          token: token,
        },
      });

      socket.on("connect", () => {
        console.log("socket.io connection established");
        setIsCaptureEnabled(true);
        resolve(true);
      });

      socket.on("disconnect", (reason: string) => {
        if (reason === "io server disconnect") {
          console.log("socket.io closed. reconnecting...");
          setTimeout(() => {
            socket.connect();
          }, retryDelayMs);
        } else {
          console.log("socket.io closed cleanly");
        }
      });

      socket.on("connect_error", (error: Error) => {
        if (!socket.active) {
          console.error("socket.io error:", error, audio_file_id);
          Swal.fire({
            icon: "error",
            title: "Connection Error",
            text: "Unable to connect to the server. Please check your internet connection and try again.",
          });
        }
      });

      socket.on("reconnect_failed", () => {
        console.error("Failed to reconnect: ", audio_file_id);
        Swal.fire({
          icon: "error",
          title: "Connection Lost",
          text: "We couldn't reconnect to the server. Please refresh your browser and try again. If the problem persists, our team has been notified.",
        });
        resetRecordingState();
      });

      socket.on("message", (data: { id: string }) => {
        console.log("Message from server from:", data);
      });

      socket.connect();

      // Assign the Socket.IO connection to a ref so it can be used elsewhere in your component
      console.log("assigning the socket.io to the captureaudioref");
      captureAudioRef.current = socket;
    });
  };

  const closeWebsocket = () => {
    if (captureAudioRef.current) {
      captureAudioRef.current.emit("reset");
      captureAudioRef.current.disconnect();
      captureAudioRef.current = null;
    }
  };

  const resetRecordingState = (withoutRefresh: Boolean = false) => {
    chunks = [];
    dispatch(setShowRecordingView(false));
    dispatch(setSubmissionProgressState(false));
    dispatch(setMediaRecorder(null));
    if (recordingInterval.current) {
      clearInterval(recordingInterval.current);
      recordingInterval.current = null;
    }
    setRecordingTime(0);
    dispatch(setUploadType(0));
    captureRecordingIdRef.current = "";
    closeWebsocket();
    dispatch(
      setRecordingDetail({
        title: "",
        note_type: NoteType.SOAP,
        category_type: 0,
        modality_type: "audio",
        gender_type: 0,
        language_type: 0,
        client_id: null,
      }),
    );
    dispatch(setCreateModalState(false));
    dispatch(setEditingState(false));
    dispatch(setSelectedNoteForEditing(null));
    dispatch(setIsRecording(false));
    dispatch(setIsPaused(false));
    if (!withoutRefresh) {
      eventEmitter.emit("refreshNotes");
    }
    dispatch(setUploadProgressState(false));
  };

  const displayRecordingTimer = () => {
    // Start a timer to track recording time
    recordingInterval.current = setInterval(() => {
      if (recordingTime >= MAX_RECORDING_TIME_SECONDS) {
        return recordingTime;
      }
      setRecordingTime((time) => time + 1);
    }, 1000);
  };

  React.useEffect(() => {
    if (isRecording && recordingTime >= MAX_RECORDING_TIME_SECONDS) {
      handleStopRecording();
      handleSubmitAudio();
    }
  }, [isRecording, recordingTime]);

  const handleStopRecording = async () => {
    await releaseWakeLock();
    dispatch(setIsRecording(false));
    if (mediaRecorder?.state === "inactive") {
      return;
    }
    trackEvent(EventType.STOP_BUTTON, {
      modalityMode: recordingDetail.modality_type,
    });
    dispatch(setIsPaused(true));
    mediaRecorder?.pause();
    if (recordingInterval.current) {
      clearInterval(recordingInterval.current);
      recordingInterval.current = null;
    }
  };

  const handleResumeRecording = async () => {
    if (!networkState.online) {
      Swal.fire({
        icon: "error",
        title: "Unable to Resume Session",
        text: "It looks like you're currently offline. Make sure your device is connected to Wi-Fi or mobile data before resuming your session.",
      });
      return;
    }
    await requestWakeLock();
    dispatch(setIsPaused(false));
    displayRecordingTimer();
    mediaRecorder?.resume();
    dispatch(setIsRecording(true));
    dispatch(setMediaRecorder(mediaRecorder));
    trackEvent(EventType.RESUME_BUTTON, {
      modalityMode: recordingDetail.modality_type,
    });
  };

  const handleSubmitAudio = () => {
    if (!networkState.online) {
      Swal.fire({
        icon: "error",
        title: "Unable to Submit Recording",
        text: "It looks like you're currently offline. Make sure your device is connected to Wi-Fi or mobile data before submitting your recording.",
      });
      return;
    }
    try {
      if (isTooShort(recordingTime)) {
        Swal.fire({
          icon: "error",
          title: "Recording Too Short",
          text: "This recording is too short. Please record for at least 15 seconds, then try again.",
        });
        return;
      }

      if (isLastPlusOneNote(userInfo)) {
        dispatch(setShowPaymentModal(true));
      }

      dispatch(setSubmissionProgressState(true));
      trackEvent(EventType.SUBMIT_AUDIO_MODAL, {
        modalityMode: recordingDetail.modality_type,
      });
      stopMediaRecorderAndStopTracks(mediaRecorder);
      completeSubmission(isLastPlusOneNote(userInfo));
      totalBufferCountRef.current = 0;
    } catch (error) {
      dispatch(setSubmissionProgressState(false));
      Sentry.captureException(error);
      Swal.fire({
        icon: "error",
        title: "Error submitting audio",
        text: "Please try again or contact support.",
      });
    }
  };

  React.useEffect(() => {
    return () => {
      releaseWakeLock();
    };
  }, []);

  /**
   * If media Recorder is not null, add an error event listener to it
   */
  React.useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.addEventListener("error" as any, (event: any) => {
        Sentry.captureException(event.error);
      });
    }

    return () => {
      if (mediaRecorder) {
        mediaRecorder.removeEventListener("error" as any, () => {
          console.log("error event listener removed");
        });
      }
    };
  }, [mediaRecorder]);

  const completeSubmission = async (isLocked = false) => {
    // If we're coming through a capture
    if (uploadType === 0) {
      // One final flush
      console.log(
        "about to call stop of media recorder in complete submission",
      );
      try {
        const formData = new FormData();
        formData.append("audio_id", captureRecordingIdRef.current);
        formData.append("title", recordingDetail.title ?? "Untitled Session");
        formData.append("note_type", recordingDetail.note_type.toString());
        formData.append(
          "category_type",
          recordingDetail.category_type.toString(),
        );
        formData.append("content_type", recordMimeType || "audio/webm");
        formData.append("modality_type", recordingDetail.modality_type);
        formData.append(
          "language_type",
          recordingDetail.language_type.toString(),
        );
        formData.append("gender_type", recordingDetail.gender_type.toString());
        if (
          recordingDetail.client_id !== undefined &&
          recordingDetail.client_id !== null
        ) {
          formData.append("client_id", recordingDetail.client_id.toString());
        }
        if (
          recordingDetail.location_type !== undefined &&
          recordingDetail.location_type !== null
        ) {
          formData.append(
            "location_type",
            recordingDetail.location_type.toString(),
          );
        }
        if (
          recordingDetail.duration_type !== undefined &&
          recordingDetail.duration_type !== null
        )
          formData.append(
            "duration_type",
            recordingDetail.duration_type.toString(),
          );

        if (
          recordingDetail.duration_addon_type !== undefined &&
          recordingDetail.duration_addon_type !== null
        )
          formData.append(
            "duration_addon_type",
            recordingDetail.duration_addon_type.toString(),
          );

        if (isLocked) {
          formData.append("is_locked", "true");
        }

        // Send the audio file to your API endpoint
        const response = await handleCaptureAudioSubmission(formData);
        console.log("File Upload Response:", response);
        if (response.status === 201) {
          trackEvent(EventType.SUBMIT_AUDIO_FINAL, {
            audioFileId: response?.data?.id,
            modalityMode: recordingDetail.modality_type,
          });
          resetRecordingState();
          return;
        }
        console.error("Backend Error Response:", response);
        // replace below alet with sweetalert2 alert
        Swal.fire({
          icon: "error",
          title: "Audio upload and processing failed.",
          text: "Please try again.",
        });
        resetRecordingState();
        return;
      } catch (error) {
        console.error("Upload Error:", error);
        // replace below alet with sweetalert2 alert
        Swal.fire({
          icon: "error",
          title: "Audio upload and processing failed.",
          text: "Please try again.",
        });
        resetRecordingState();
        return;
      }
    }
  };

  React.useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        console.log("Page is now in the foreground!");
        await requestWakeLock();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const deleteRecording = async () => {
    Swal.fire({
      title: "Would you like to cancel?",
      text: "You will lose this session if you cancel.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Cancel it!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        if (captureRecordingIdRef.current) {
          handleRetry();
        } else {
          Sentry.captureMessage("No recording id to delete");
        }
        dispatch(setShowRecordingView(false));
        closeWebsocket();
        handleStopRecording();
        stopMediaRecorderAndStopTracks(mediaRecorder);
        totalBufferCountRef.current = 0;
      }
    });
  };

  const handleRetry = async () => {
    if (captureRecordingIdRef.current) {
      const updateNoteData = {
        audio_id: captureRecordingIdRef.current,
        status: "cancel",
      };
      await handleCancelRecording(updateNoteData).catch((error) => {
        console.error("Error cancelling recording:", error);
      });
    }
    resetRecordingState();
  };

  const items = [
    {
      key: "2",
      label: "Record",
      children: (
        <>
          {isMicrophoneMuted && (
            <Alert
              message="Microphone is muted. Please unmute to record properly."
              banner
              closable
            />
          )}
          <Col
            span={24}
            md={10}
            xl={12}
            className={styles[`${componentName}__container-right`]}
          >
            <Flex vertical align="center">
              {showRecordingView ? (
                <ActiveRecordingView
                  isPaused={isPaused}
                  recordingTime={recordingTime}
                  handleStopRecording={handleStopRecording}
                  handleResumeRecording={handleResumeRecording}
                  handleSubmitAudio={handleSubmitAudio}
                  deleteRecording={deleteRecording}
                />
              ) : (
                <RecordCTA />
              )}
            </Flex>
          </Col>
        </>
      ),
      icon: <FileAddOutlined />,
    },
    {
      key: "1",
      label: "Notes",
      children: (
        <Col
          span={24}
          md={12}
          className={styles[`${componentName}__container-left`]}
          id="notes-container-mobile"
        >
          <Outlet />
        </Col>
      ),
      icon: <FileTextOutlined />,
    },
  ];

  React.useEffect(() => {
    const { isNoteModalOpen, isClientModalOpen, recordingDetails, clientForm } =
      demoState;

    if (isNoteModalOpen || isClientModalOpen) {
      if (isNoteModalOpen) {
        dispatch(setRecordingDetail(recordingDetails));
        dispatch(setCreateModalState(true));
      }
      if (isClientModalOpen) {
        dispatch(setCreateClientState(true));
      }
      if (clientForm) {
        dispatch(setAddClientView(true));
        dispatch(setClientForm(clientForm));
      }
      dispatch(
        setDemoState({
          isNoteModalOpen: false,
          recordingDetails: null,
          clientForm: null,
          isClientModalOpen: false,
        }),
      );
    }
  }, [demoState, dispatch]);

  React.useEffect(() => {
    if (!mediaRecorder || !isRecording) return;

    const interval = setInterval(() => {
      
      if (currentBufferRef.current === previousBufferRef.current) {
        noBufferCountRef.current += 1;
      } else {
        noBufferCountRef.current = 0;
      }
      previousBufferRef.current = currentBufferRef.current;
      if (noBufferCountRef.current >= 30) {
        Sentry.captureException(
          new Error("Data Corruption"), {
            "extra": {
              audio_devices: audioDevices,
              selected_device: audioDevices ? audioDevices[0] : null,
              mediaRecorder: {
                state: mediaRecorder?.state,
                streamActive: mediaRecorder?.stream?.active,
                mimeType: mediaRecorder?.mimeType,
              },
              isRecording: isRecording,
              noBufferCountRef: noBufferCountRef.current,
              audioFileId: captureRecordingIdRef.current,
              totalBufferCount: totalBufferCountRef.current,
            }
          }
        );
        noBufferCountRef.current = 0;
      }
    }, 1000);

    return () => { clearInterval(interval) };
  }, [mediaRecorder, isRecording]);

  return (
    <>
      {isMicrophoneMuted && (
        <Alert
          message="Microphone is muted. Please unmute to record properly."
          banner
          closable
        />
      )}
      <Row className={styles[`${componentName}__container`]}>
        <Col
          span={24}
          md={6}
          lg={6}
          xl={6}
          className={styles[`${componentName}__container-side`]}
        >
          <Flex vertical align="center">
            {showRecordingView ? (
              <ActiveRecordingView
                isPaused={isPaused}
                recordingTime={recordingTime}
                handleStopRecording={handleStopRecording}
                handleResumeRecording={handleResumeRecording}
                handleSubmitAudio={handleSubmitAudio}
                deleteRecording={deleteRecording}
              />
            ) : (
              <RecordCTA />
            )}
          </Flex>
          <Divider
            className={styles[`${componentName}__container-side-divider`]}
          />
          <Flex>
            <ClientView />
          </Flex>
        </Col>
        <Col
          span={24}
          md={18}
          lg={18}
          xl={18}
          className={styles[`${componentName}__container-left`]}
          id="notes-container"
        >
          <Outlet />
        </Col>
        {isCreateClientDrawerVisible && <CreateClient />}
        {isCreateModalVisible && (
          <CreateNoteModal
            chunks={chunks}
            captureRecordingIdRef={captureRecordingIdRef}
            captureAudioRef={captureAudioRef}
            isCaptureEnabled={isCaptureEnabled}
            resetRecordingState={resetRecordingState}
            handleRetry={handleRetry}
            initWebsocket={initWebsocket}
            setRecordingTime={setRecordingTime}
            displayRecordingTimer={displayRecordingTimer}
            checkMicrophoneMuteStatus={(stream: MediaStream) => checkMicrophoneMuteStatus(stream, setIsMicrophoneMuted)}
            ref={currentBufferRef}
            onBufferCountChange={(count: number) => {
              totalBufferCountRef.current += count;
            }}
          />
        )}
        <Share />
      </Row>
      <Drawer
        title="Customize Note"
        open={isCustomiseSectionVisible}
        placement={isMobileView ? "bottom" : "right"}
        height={isMobileView ? "85%" : "100%"}
        closable={true}
        destroyOnClose
        onClose={() => dispatch(setCustomiseSectionState(false))}
        width={isMobileView ? "100%" : "450px"}
      >
        <Flex
          vertical
          justify="space-between"
          style={{
            height: "100%",
          }}
        >
          {notesDetails &&
            (notesDetails.note_type === 0 || notesDetails.note_type === 2) && (
              <Flex
                className={`${styles[`${componentName}__customise_clearance`]}`}
              >
                <span />
              </Flex>
            )}
          <CustomiseNote noPadding={true} />
        </Flex>
      </Drawer>
      <Tabs
        destroyInactiveTabPane
        tabPosition={"bottom"}
        size="large"
        items={items}
        className={`${styles[`${componentName}__mobile_view_tabs`]}`}
        centered
      />
    </>
  );
};

export default HomePage;
